<template>
  <div class="welfare-component">
    <div class="margin"></div>
    <div class="welfare-title">
      <img
  src="//download-cos.yofish.com/yofish-gongjushiyebu/20230516170147172-welfare-label.png" alt="" />
    </div>
    <div class="welfare-code">{{ newInviteCode }}</div>
    <!-- <div class="welfare-copy-btn copyBtn"
      :data-clipboard-text="inviteCode" @click="copyAction">复制券码</div> -->
    <div class="welfare-copy-text">复制券码，打开有鱼记账APP即可获得新人福利：有鱼7天会员</div>
    <div class="welfare-btn copyBtn"
      @click="openOrDownloadApp" :data-clipboard-text="newInviteCode">立即复制领取</div>
  </div>
</template>
<script>
import { isChinese } from '@/utils';

export default {
  name: 'Welfare',
  props: {
    inviteCode: {
      type: String,
      default: '',
    },
  },
  computed: {
    newInviteCode() {
      if (window.escape(this.inviteCode).indexOf('%u') >= 0) {
        return this.inviteCode;
      }
      return decodeURI(this.inviteCode);
    },
  },
  methods: {
    copyAction() {
      const clipboard = new window.Clipboard('.copyBtn');
      clipboard.on('success', (_) => {
        // this.$toast({
        //   content: '复制成功，打开有鱼记账app即可体验您的专属会员特权',
        // });
        clipboard?.destroy();
      });
      clipboard.on('error', (_) => {
        console.log('浏览器不支持复制');
        clipboard?.destroy();
      });
      window?._monitor('track', 'xrfly_fzqman_click', {
        eventName: '新人福利页-复制券码按钮点击',
      });
    },
    openOrDownloadApp() {
      this.copyAction();
      window?._monitor('track', 'xrfly_ljlqan_click', {
        eventName: '新人福利页-立即领取按钮点击',
      });
      window.setTimeout(() => {
        this.$emit('download-app');
      }, 600);
    },
  },
};
</script>
<style lang="scss" scoped>
.welfare-component {
  text-align: center;
}
.margin {
  padding-top: 86px;
}
.welfare-title {
  width: 444px;
  height: 73px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  img {
    width: 100%;
    height: 100%;
  }
}
.welfare-code {
  font-size: 52px;
  font-weight: 500;
  color: #EF5B53;
  line-height: 44px;
}
.welfare-copy-btn {
  font-size: 26px;
  font-weight: 500;
  color: #EF5B53;
  line-height: 45px;
  width: 141px;
  height: 45px;
  background: rgba(#EF5B53, .1);
  border-radius: 23px 23px 23px 23px;
  text-align: center;
  margin: 46px auto 0;
  cursor: pointer;
}
.welfare-copy-text {
  width: 495px;
  text-align: left;
  font-size: 26px;
  font-weight: 400;
  color: #EF5B53;
  line-height: 44px;
  margin: 65px auto 0;
}
.welfare-btn {
  font-size: 35px;
  color: #ffffff;
  width: 563px;
  height: 80px;
  background: #EF5B53;
  border-radius: 40px 40px 40px 40px;
  opacity: 1;
  text-align: center;
  line-height: 80px;
  margin: 56px auto 0;
  cursor: pointer;
}
</style>
