<template>
  <div class="register-page img-common">
    <div class="register-head img-common">
      <div class="logo">
        <div class="image-logo">
          <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221212110714718-logo.png" alt="" />
        </div>
        <div class="logo-name">有鱼记账</div>
      </div>
      <div class="logo-desc">开启记账人生 实现财富自由</div>
    </div>
    <div class="register-wrapper">
      <template v-if="ptype === 'welfare'">
        <r-welfare
          :inviteCode="inviteCode"
          @download-app="dowloadAppAction" />
      </template>
      <template v-else>
        <div class="register-title">
          <span>手机验证注册</span>
        </div>
        <div class="re-form">
          <div class="re-input phone-number">
            <input type="text" v-model="phoneNumber" placeholder="请输入手机号" maxlength="11">
          </div>
          <div class="veri-model">
            <div class="re-input veri-code">
              <input type="text" v-model="code" placeholder="请输入短信验证码"
                maxlength="6"/>
            </div>
            <div class="sms-btn" @click="smsAction"
              >{{ veriTxt }}</div>
          </div>
          <div class="register-btn disabled" v-if="code.length !== 6 || !isNumbers(code)">一键注册</div>
          <div class="register-btn" v-else
            @click="checkRegisterAction">一键注册</div>
        </div>
      </template>
    </div>
    <div class="r-modular modular1">
      <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230104182336158-modular1.png" alt="" />
    </div>
    <div class="r-modular modular2">
      <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230112133653500-card2.png" alt="" />
    </div>
    <div class="r-modular modular3">
      <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230104182403563-modular3.png" alt="" />
    </div>
    <div class="r-modular modular4">
      <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230112133708183-card4.png" alt="" />
    </div>
    <div class="r-modular modular5">
      <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230104182431765-modular5.png" alt="" />
    </div>
    <div class="r-modular modular6">
      <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230104182536886-modular6.png" alt="" />
    </div>
    <div class="modular-footer">
      <span>更多功能请下载有鱼记账APP</span>
    </div>
    <y-dialog :visible.sync="visible" class="dowload-dialog">
      <div class="dowload-dialog-row">
        <div class="content">
          <p class="title">注册完成</p>
          <p class="desc">去下载APP</p>
        </div>
        <div class="btn" @click="clickAction">立即下载</div>
      </div>
    </y-dialog>
    <y-dialog :visible.sync="veriVisible" class="veri-dialog">
      <div class="veri-dialog-row">
        <div class="veri-content">
          <p class="veri-desc">请输入图片验证码, 表明你是地球人</p>
          <div class="veri-image" @click="updateImageVeri">
            <img :src="veriImageUrl" alt="">
            <div class="refresh-icon">
              <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20230104173324618-refresh.png" alt="" />
            </div>
          </div>
          <div class="veri-input">
            <input type="text" v-model="imageCode" placeholder="请输入图形验证码"
              ref="veriInput"/>
          </div>
        </div>
        <div class="btn" @click="submitAction">提交</div>
      </div>
    </y-dialog>
    <div class="mask-modal" v-show="sharModal">
      <div class="share-img">
        <img src="//jz.yofish.com/5/public/img/shareImg.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import YDialog from '@/components/dialog';
import RWelfare from './components/welfare';
import {
  checkPhone,
  randomString,
  debounce,
  yuuid,
  userAgentType,
  downloadAppUrl,
} from '@/utils';
import {
  checkPhoneRegisterApi,
  sendPhoneSmsApi,
  mobileRegisterApi,
  updatePhoneSmsApi,
} from '@/api/base1';

const CODE_TXT = '获取验证码';
const RE_CODE_TEXT = '重新获取验证码';
const countDownMax = 60;

export default {
  name: 'Register',
  components: { YDialog, RWelfare },
  data() {
    return {
      phoneNumber: '',
      code: '',
      visible: false,
      isDisabled: false,
      veriTxt: CODE_TXT,
      veriVisible: false,
      veriImageUrl: '',
      imageCode: '',
      sharModal: false,
    };
  },
  mounted() {
    const { ptype } = this;
    document.title = ptype === 'welfare' ? '新人福利' : '新人注册';
    const { isShare } = this.$route.query;
    if (!this.isNavgator && isShare) {
      this.visible = true;
    }
    this.blindMonitor();
  },
  computed: {
    inviteCode() {
      return this.$route?.query?.inviteCode;
    },
    source() {
      return this.$route?.query?.source;
    },
    isPhone() {
      return userAgentType().isPhone;
    },
    iosAppUrl() {
      return downloadAppUrl().iosUrl;
    },
    isNavgator() {
      const ua = navigator.userAgent;
      return (ua.toLowerCase().indexOf('micromessenger') !== -1
				|| ua.match(/QQ\//i) === 'QQ/'
				|| ua.toLowerCase().indexOf('weibo') !== -1);
    },
    ptype() { // 页面类型
      return this.$route?.query?.ptype;
    },
    psource() { // 页面来源
      return this.$route?.query?.psource;
    },
  },
  watch: {
    visible(val) {
      val && this.lockScroll();
      !val && this.removeLockScroll();
    },
    veriVisible(val) {
      val && this.lockScroll();
      !val && this.removeLockScroll();
    },
    sharModal(val) {
      val && this.lockScroll();
      !val && this.removeLockScroll();
    },
  },
  methods: {
    clickAction() {
      const { iosAppUrl, isPhone } = this;
      if (isPhone) {
        window.location.href = iosAppUrl;
        return false;
      }
      if (this.isNavgator) {
        this.sharModal = true;
        return false;
      }
      window.location.href = '//sj.qq.com/appdetail/com.jz.youyu';
    },
    async checkPhoneRegister(status) {
      let res;
      const { phoneNumber } = this;
      if (this.isCheck) return false;
      this.isCheck = true;
      try {
        res = await checkPhoneRegisterApi({ cmobileNo: phoneNumber });
      } catch (e) {
        console.log(e);
        window.setTimeout(() => {
          this.isCheck = false;
        }, 800);
        if (e?.code === -3333 && !status) return this.sendSmsAction();
        if (e?.code === -3333 && status) return this.registerAction();
        if (!status) return this.$toast('验证码信息不存在或已过期');
        if (status) return this.$toast(e?.desc || '网络异常');
      }
      window.setTimeout(() => {
        this.isCheck = false;
      }, 800);
      if (res?.code === 0) {
        !status && this.sendSmsAction();
        status && this.registerAction();
      }
      if (res?.code === 1) {
        this.$toast('该账号已注册！');
      }
    },
    smsAction: debounce(function () {
      const { phoneNumber, isSubmit, isDisabled } = this;
      if (phoneNumber === '') return this.$toast('请输入正确的手机号！');
      if (!checkPhone(phoneNumber)) return this.$toast('请输入正确的手机号！');
      if (isSubmit || isDisabled) return false;
      this.checkPhoneRegister();
    }, 800, true),
    async sendSmsAction() {
      let res;
      const {
        phoneNumber,
        isSubmit, isDisabled,
        imageCode,
      } = this;
      let timeStamp = new Date().getTime();
      const signMsg = window?.hex_md5(phoneNumber + timeStamp + 'iwannapie?!').toUpperCase();
      if (isSubmit || isDisabled) return false;
      this.isSubmit = true;
      this.isDisabled = true;
      try {
        res = await sendPhoneSmsApi({
          cmobileNo: phoneNumber,
          yzmType: 13,
          channelType: 0,
          timeStamp,
          signMsg,
          imgYzm: imageCode || '',
        });
      } catch (e) {
        console.log(e);
        this.isSubmit = false;
        this.isDisabled = false;
        return this.$toast(e?.desc || '网络异常');
      }
      if (res?.code === 2) {
        this.isSubmit = false;
        this.isDisabled = false;
        this.veriAction(res);
        return false;
      }
      if (res?.code === 1) {
        this.remainTime = countDownMax;
        this.veriVisible = false;
        this.countDown(); // 倒计时
        return false;
      }
      this.isDisabled = false;
      this.isSubmit = false;
      return this.$toast(res?.desc || '网络异常');
    },
    // 倒计时
    countDown() {
      if (this.remainTime <= 0) {
        this.timer && window.clearTimeout(this.timer);
        this.remainTime = countDownMax;
        this.isDisabled = false;
        this.veriTxt = CODE_TXT;
        this.startCountDown = false;
        return false;
      }
      this.remainTime--;
      this.veriTxt = `${this.remainTime}s`;
      this.timer = window.setTimeout(() => {
        this.countDown();
      }, 1000);
    },
    checkRegisterAction() {
      const {
        phoneNumber, code, inviteCode,
        source,
      } = this;
      if (phoneNumber === '') return this.$toast('请输入正确的手机号！');
      // console.log('phoneNumber---', phoneNumber);
      if (!checkPhone(phoneNumber)) return this.$toast('请输入正确的手机号！');
      this.checkPhoneRegister('register');
    },
    registerAction: debounce(async function () {
      const {
        phoneNumber, code, inviteCode,
        source,
      } = this;
      if (phoneNumber === '') return this.$toast('请输入正确的手机号！');
      // console.log('phoneNumber---', phoneNumber);
      if (!checkPhone(phoneNumber)) return this.$toast('请输入正确的手机号！');
      let cuserId = yuuid();
      console.log('userid---', cuserId);
      let pwd = randomString(10);
      pwd = window?.hex_md5(pwd + 'http://www.9188.com/').toLowerCase();
      let res;
      const params = {
        cmodel: '',
        cphoneos: '',
        cphonebrand: '',
        cimei: '',
        cmobileno: phoneNumber,
        yzm: code,
        pwd,
        inviteCode,
        yzmType: '13',
      };
      console.log(params);
      try {
        res = await mobileRegisterApi(params, { source: '12034', cuserId });
      } catch (e) {
        console.log(e);
        return this.$toast(e?.desc || '网络异常');
      }
      if (res?.code === 1) {
        window.history.pushState({}, 0, window.location.href + '&isShare=1');
        this.visible = true;
      }
    }, 900, true),
    async updateImageVeri() {
      let res;
      const { phoneNumber } = this;
      if (this.isUpdating) return false;
      this.isUpdating = true;
      try {
        res = await updatePhoneSmsApi({
          cmobileNo: phoneNumber,
        });
      } catch (e) {
        console.log(e);
        this.isUpdating = false;
      }
      if (res?.code === 1) {
        this.veriAction(res);
        window.setTimeout(() => {
          this.isUpdating = false;
        }, 500);
        return false;
      }
      this.isUpdating = false;
    },
    veriAction(res) {
      let base64 = res?.results?.image || '';
      if (base64) {
        base64 = base64.replace(/[\r\n]/g, '');
        this.veriImageUrl = `data:image/png;base64,${base64}`;
        console.log(this.veriImageUrl);
      }
      this.veriVisible = true;
      this.$nextTick(() => {
        this.$refs.veriInput.focus();
      });
    },
    submitAction() {
      if (this.imageCode === '') return this.$toast('请输入图形验证码');
      this.sendSmsAction();
    },
    lockScroll() {
      document.body.classList.add('nut-overflow-hidden');
    },
    removeLockScroll() {
      document.body.classList.remove('nut-overflow-hidden');
    },
    isNumbers(val) {
      const reg = /^[0-9]*$/;
      return reg.test(val);
    },
    blindMonitor() {
      const { ptype } = this;
      window._monitor('init', {
        businessType: 0,
        businessId: this.psource,
        cuserId: '',
      });
      let event = '';
      let eventName = '';
      if (ptype === 'welfare') {
        event = 'xrfly_imp';
        eventName = '新人福利页曝光';
        window._monitor('pageset', event, {
          eventName,
        });
      }
    },
    dowloadAppAction() {
      const { iosAppUrl, isPhone, ptype } = this;
      if (isPhone) {
        window.location.href = iosAppUrl;
        return false;
      }
      if (ptype === 'welfare') {
        window.location.href = '//download.yofish.com/jz/yyjz.apk';
        return false;
      }
      if (this.isNavgator) {
        this.sharModal = true;
        return false;
      }
      window.location.href = '//jz.yofish.com/app/index.html';
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/index.scss";
@import "./index.scss";
</style>
